<template>
  <div id="star" :style="{ width: width + 'px', height: height + 'px' }">
    <template v-if="typeOfStar === 'full'">
      <svg
        version="1.1"
        id="Layer_1"
        focusable="false"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 576 512"
        style="enable-background: new 0 0 576 512"
        xml:space="preserve"
        class="svg-inline--fa fa-star fa-w-18 star-full"
        :class="{ active: aboveBenchmark === true }"
      >
        <circle cx="288" cy="256" r="256" :style="{ fill: getStarColor }" />
      </svg>
    </template>
    <template v-if="typeOfStar === 'half'">
      <!-- <svg version="1.1" id="Layer_1" focusable="false" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 576 512" style="enable-background:new 0 0 576 512;" xml:space="preserve" class="svg-inline--fa fa-star-half-alt fa-w-17 star-half" :class="{'active':aboveBenchmark === true}">
        <path d="M288,0C146.6,0,32,114.6,32,256s114.6,256,256,256V251.7V0z"/>
      </svg> -->
      <svg
        version="1.1"
        id="Layer_1"
        focusable="false"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 576 512"
        style="enable-background: new 0 0 576 512"
        xml:space="preserve"
        class="svg-inline--fa fa-star-half-alt fa-w-17 star-half"
        :class="{ active: aboveBenchmark === true }"
      >
        <path
          d="M288,0C146.6,0,32,114.6,32,256s114.6,256,256,256V251.7V0z"
          :style="{ fill: getStarColor }"
        />
        <path
          class="st0"
          d="M288,512c141.4,0,256-114.6,256-256S429.4,0,288,0v260.3V512z"
          style="fill: #5d5d5d; fill-opacity: 0.2"
        />
      </svg>
    </template>
    <template v-if="typeOfStar === 'empty'">
      <svg
        version="1.1"
        id="Layer_1"
        focusable="false"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 576 512"
        style="enable-background: new 0 0 576 512"
        xml:space="preserve"
        class="svg-inline--fa fa-star fa-w-18 star-empty"
        :class="{ active: aboveBenchmark === true }"
      >
        <circle cx="288" cy="256" r="256" />
      </svg>
    </template>
  </div>
</template>
<script>
export default {
  name: 'star',
  props: {
    typeOfStar: String,
    aboveBenchmark: Boolean,
    width: Number,
    height: Number,
    starColor: Boolean
  },
  computed: {
    getStarColor() {
      // prettier-ignore
      return this.starColor === null
        ? ''
        : this.starColor === true
          ? '#1098B2'
          : '#CC953A'
    }
  }
}
</script>

<style lang="scss">
#star {
  // height: 20px;
  // width: 20px;

  display: flex;

  .active {
    fill: $blue !important;
  }

  .fa-star,
  .fa-star-half-alt {
    height: 100%;
    width: 100%;
  }

  .star-full {
    fill: $off-blue;
  }
  .star-half {
    fill: $off-blue;
  }
  .star-empty {
    fill: $grey-blue !important;
    fill-opacity: 0.2;
  }
}
</style>
